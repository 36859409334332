import Vue from 'vue'
import Vuex from 'vuex'
import notifications from './notifications';
import notification from './notification';
import accounts from './accounts';
import ratings from './ratings';
import { apis } from '../config';
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    auth: null,
  },
  getters: {
    isLogged(state){
      return !!state.auth;
    }
  },
  mutations: {
    logged(state, payload){
      state.auth = payload;
    },
    logout(state){
      state.auth = null;
    }
  },
  actions: {
    async login(state, {username, password}){
      const {token} = await axios.post(apis.login, {username, password}).then(result => result.data);
      axios.defaults.headers['Authorization'] = `Bearer ${token}`;
      state.commit('logged', {username});
    },
    logout(state){
      state.commit('logout');
      delete axios.defaults.headers['Authorization'];
      window.location.href = "/";
    }
  }, 
  modules: {
    notifications,
    notification,
    ratings,
    accounts
  }
})
