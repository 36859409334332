import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import ('../views/Login.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    children: [
      {
        path: 'notifications',
        name: 'Notifications',
        component: () => import('../views/Notification/Notification.vue'),
        children: [
          {
            path: 'all',
            component: () => import('../views/Notification/All.vue'),
          },
          {
            path: 'create',
            component: () => import('../views/Notification/Form.vue')
          },
          {
            path: 'edit/:id',
            component: () => import('../views/Notification/Form.vue')
          }
        ]
      },
      {
        path: 'ratings',
        name: 'Ratings',
        component: () => import('../views/Rating/Rating.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
