import axios from 'axios';
import { apis } from '../config';

export default {
    namespaced: true,
    state: {
        data: [],
        loading: false,
        total: 0
    },
    getters: {
    },
    mutations: {
        update(state, args) {
            Object.assign(state, args);
        }
    },
    actions: {
        async fetchRatings(state, { page } = { page: 1 }) {
            state.commit('update', { loading: true });
            const { ratings: { data, meta: { last_page } } } = await axios.get(apis.ratings + `?page=${page}`).then(result => result.data);
            state.commit('update', { data, total: last_page, loading: false });
        }
    }
}
