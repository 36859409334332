import Vue from 'vue'
import axios from 'axios';
import { apis } from '../config';

export default {
    namespaced: true,
    state: {
        data: [],
        loading: false,
        total: 0
    },
    getters: {
    },
    mutations: {
        loading(state, bool = false){
            state.loading = bool;
        },
        update(state, {data, total}){
            state.data = data;
            state.total = total;
        }
    },
    actions: {
        async loadNotifications(state, {page} = {page: 1}) {
            state.commit('loading', true);
            const {notifications: {data, meta: {last_page}}} = await axios.get(apis.notifications + `?page=${page}`).then(result => result.data);
            state.commit('update', {data, total: last_page});
            state.commit('loading', false);
        },
        async delete(state, {id}){
            state.commit('loading', true);
            try{
                await axios.delete(apis.notifications + '\\' + id);
                state.dispatch('loadNotifications');
            }catch(error){
                alert('Có lỗi khi xóa');
            }
            state.commit('loading', false);
        }
    }
}
