import axios from "axios";
import { apis } from "../config";

export default {
    namespaced: true,
    state: {
        data: [],
        loading: false,
        total: 0,
        success: false,
        error: null
    },
    getters: {
    },
    mutations: {
        update(state, data) {
            Object.assign(state, data);
        }
    },
    actions: {
        async fetchAccounts(state) {
            state.commit('update', { loading: true });
            try {
                const { accounts } = await axios.get(apis.accounts).then(r => r.data);
                state.commit('update', { loading: false, data: accounts });
            } catch (error) {
                state.commit('update', { loading: false, error });
            }
        },
        async loadAccounts(state){
            state.commit('update', { loading: true });
            await axios.get(apis.accounts + '/load');
            state.dispatch('fetchAccounts');
        }
    }
}
