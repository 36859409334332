import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import './css/index.css';
import './scss/style.scss';
import moment from 'moment';
import 'moment/locale/vi';

Vue.config.productionTip = false

Vue.use(Vuesax, {
  // options here
})

Vue.prototype.moment = moment

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
