import Vue from 'vue'
import axios from 'axios';
import { apis } from '../config';

export default {
    namespaced: true,
    state: {
        data: {},
        loading: false,
        success: false
    },
    getters: {
        data: state => state.data,
        loading: state => state.loading,
        success: state => state.success,
        notification: state => state.notification
    },
    mutations: {
        update(state, data){
            Object.assign(state, data);
        },
        reset(state){
            state.data = {};
            state.loading = null;
            state.success = null;
        }
    },
    actions: {
        async loadNotification(state, {id}) {
            state.commit('update', {loading: true, success: false});
            const {notification} = await axios.get(apis.notifications + `/${id}`).then(result => result.data);
            state.commit('update', {data: notification, loading: false });
        },
        async updateNotification(state, data){
            state.commit('update', {loading: true});
            try{
                let response;
                if(data.has('id')){
                    response = await axios.put(apis.notifications + `\\${data.get('id')}`, data).then(result => result.data);
                }else{
                    response = await axios.post(apis.notifications, data).then(result => result.data);
                }
                state.commit('update', {loading: false, success: true, data: response.notification});
            }catch(error){
                alert('Có lỗi khi cập nhập')
                state.commit('update', {loading: false});
            }
        }
    }
}
